.btn.btn-bnp {
    background-color: #00915A;
    color: white;
}

.btn.btn-bnp:focus {
    box-shadow: none;
}

.btn.btn-bnp:hover:enabled {
    background-color: #006d43;
    color: white;
}

.card-img-picto {
    width: auto;
    height: 64px;
    margin: auto;
    margin-bottom: 20px;
}

.text-xs-17 {
    font-size: 17px;
}

.file-link {
    color: #232323;
}

.file-link:hover {
    color: #000000;
}

@media (min-width: 1800px) {
    .debriefing-container {
        max-width: 90% !important;
    }
}