.summary {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: white;
    height: 230px;
}

.summary h1 {
    margin: 0;
    text-transform: uppercase;
    font-size: 1.5rem;
}

.summary-margin {
    margin: 40px 0 40px 0;
}

.flex-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.history {
    width: calc(100% / 6);
    cursor: default;
    background: none;
    border: none;
    outline: none;
    text-align: left;
    padding: 0;
}

.history:focus {
    outline: none;
}

.input-flex-container {
    display: flex;
    width: 100%;
    padding-left: calc(100% / 9);
    z-index: 0;
    align-items: flex-end;
    justify-content: space-around;
    flex-wrap: wrap;
    height: 100%;
}

.history::after {
    content: "";
    display: block;
    position: absolute;
    bottom: calc(25px / 2);
    transform: translateY(50%);
    background-color: #ececec;
    width: calc(100% / 6.85 - 25px);
    height: 2px;
    margin-left: 25px;
}

.history:last-child::after {
    display: none;
}

.history .dot {
    width: 25px;
    height: 25px;
    background-color: #00915A;
    position: relative;
    border-radius: 50%;
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 3px solid white;
}

.history .dot-info {
    display: flex;
    align-items: center;
    width: 100%;
    color: #00915A;
    padding-right: 10px;
    align-items: flex-end;
}

.history .dot-info>* {
    flex: 0 0 auto;
    max-width: calc(100% - 35px);
}

.history:disabled .dot-info {
    color: #ececec;
}

.history .dot-info .label-number {
    /*font-size: 2rem;
    margin-right: 5px;
    transform: scaleY(1.2);
    width: 25px;
    text-align: center;*/

    font-size: 1.5rem;
    margin-right: 5px;
    transform: scaleY(1.6);
    width: 25px;
    text-align: center
}

.history .dot-info .label {
    font-size: 0.9rem;
}

.history:disabled .dot,
.history:disabled:hover .dot {
    background-color: #ececec;
    border: none;
}

.history.filled .dot,
.history.filled::after {
    background-color: #00915A;
}

.history.filled::after {
    animation-duration: .3s;
    animation-name: slidein;
}

@keyframes slidein {
    from {
        width: 0;
    }

    to {
        width: calc(100% / 6.85 - 25px);
    }
}

.history.filled .dot:after {
    background-image: url('../../pictos/check-mark.png');
    background-size: 10px 10px;
    display: inline-block;
    width: 10px;
    height: 10px;
    content: "";
    position: absolute;
    transform: translate(50%, 50%);
}

.history.selected .dot:after {
    background: none;
}

.history.selected .dot-info,
.history.filled:hover .dot-info,
.history.filled+.history:hover .dot-info {
    color: #00492e;
}

.history:hover .dot {
    background-color: #00915A;
    border: 2px solid #00492e;
}

.history.selected:hover .dot,
.history.selected .dot {
    background-color: #00915A;
    border: 2px solid transparent;
    box-shadow: inset 0px 0px 0px 5px #ffffff;
}

@media (max-width: 768px) {
    .history .dot-info .label {
        font-size: 0.9rem;
        display: none;
    }
}